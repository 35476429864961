@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "@fontsource/inter";
//@font-face {
//    font-family: Poppins;
//    src: url(./assets/fonts/Poppins-ExtraLight.ttf);
//    font-weight: 200;
//}
//
//@font-face {
//    font-family: Poppins;
//    src: url(./assets/fonts/Poppins-Regular.ttf);
//    font-weight: 400;
//}
//
//@font-face {
//    font-family: Poppins;
//    src: url(./assets/fonts/Poppins-Medium.ttf);
//    font-weight: 500;
//}
//
//@font-face {
//    font-family: Poppins;
//    src: url(./assets/fonts/Poppins-SemiBold.ttf);
//    font-weight: 600;
//}

.united {
  .tab-pane {
    height: 100% !important;
  }
}

.status-badge {
  margin-left: 8px;
  border-radius: 4px;
  display: flex;
  padding: 4px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  color: #ffffff;
}
.status-badge-beta {
  background: #5e5ce6;
}
.btn-warning {
  border-radius: 8px !important;
  background: #ff9501 !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  color: #ffffff !important;
  margin-bottom: 8px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 40px;
  margin: 0;
}

.spark-line-custome {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 11px !important;
  line-height: 16px !important;
  color: rgba(255, 255, 255, 0.2);
}

.forcecoin-xaxis-label{
  color: #FFFFFF80;
  font-family: Inter !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 16px !important;
  letter-spacing: 0em !important;
  text-align: center !important;

}

.forcecoin-yaxis-label{

 //styleName: Title/Large;
  font-family: "Inter" !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 24px !important;
  letter-spacing: 0em !important;
  text-align: right !important;
}
.forcecoin-chart{
  .apexcharts-tooltip.apexcharts-theme-light{
    border-width: 0;
    background: #111111;
  }
  .apexcharts-tooltip {
    box-shadow: none !important;
  }

}


.forcecoin-tooltip{
  background: #111111;
  padding: 12px;
  border-radius: 8px;
  gap: 8px;
  border-color: #111111;
  .data{
    //styleName: Regular/12;
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
  }
  .price-title{
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF80;
  }
  .price-value{
    font-family: "Inter";
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    color: #FFFFFF;
  }
}


.apex-custom{
  .apexcharts-graphical {
    // transform: translate(33px, 30px) !important;
  }
  
  .apexcharts-yaxis {
    transform: translate(-34px, 0px) !important;
  }

  .apexcharts-yaxis-texts-g {
    transform: none !important;
  }
  
  .apexcharts-gridline {
    transform: translate(0px, -3px) !important;
  }
  
  .apexcharts-grid {
    // transform: rotateY(40deg);
  }
}

.container-chart {
  svg {
    overflow: visible;
  }
}



html,
body {
  height: 100%;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
}

.font-famile-for-ru-language {
  font-family: 'Poppins', sans-serif !important;
}

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-thumb {
  background-color: #474747;
  border-radius: 8px;
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0);
}

.canvas-style {
  width: 683px !important;
  height: 683px !important;
}

.inner-text-item {
  background: rgba(255, 255, 255, 0.1);

  backdrop-filter: blur(20px);
  z-index: 1;
  color: #e6e6e6 !important;
  position: relative;
  display: flex !important;
  align-items: center;

  .triangle-inner-text {
    display: flex;
    position: absolute;
    left: -5px;
    top: 47%;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 5px solid #474747;
    border-bottom: 5px solid transparent;
  }
}

.tooltip-sub {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
}

.map__circles {
  height: 100%;
}

.tooltip-data {
  padding-left: 4px;

  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.5);
}

.tooltip-description {
  text-align: start;
  width: 250px;
}

.tooltip-overtaking {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 3px;
  gap: 8px;
  margin: 2px;
  color: #f8956c;
  border-radius: 4px;
  text-align: start;
}

.tooltip-new {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 3px;
  margin: 0 4px;
  gap: 8px;
  // margin: 2px;
  background: #860eff;
  border-radius: 4px;
}

.tooltip-label {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
}

.mat-slider-track-wrapper {
  height: 4px !important;
}

.mat-slider-track-fill {
  height: 4px !important;
  background: #ff9501 !important;
}

.mat-slider-thumb {
  width: 24px !important;
  height: 24px !important;
  background: #ffffff !important;
  bottom: -12px !important;
}

.mat-slider-track-background {
  background: rgba(255, 255, 255, 0.2) !important;
  height: 4px !important;
}

@media (max-width: 1030px) {
  .canvas-style {
    width: 512px !important;
    height: 512px !important;
  }
}

@media (max-width: 540px) {
  .canvas-style {
    width: 400px !important;
    height: 400px !important;
  }
}

@media (max-width: 4000px) {
  .canvas-style {
    width: 350px !important;
    height: 350px !important;
  }
}

.loader-container {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 11;
  backdrop-filter: blur(20px);
  margin: -16px 0 0 -16px;
  .loader {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    width: 48px;
    height: 48px;
    border: 5px solid #fff;
    border-bottom-color: #333333;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.modal-content {
  border-color: none;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 64px 56px -24px rgba(0, 0, 0, 0.7);
  border-radius: 8px;
}

.card {
  background-color: #1a1a1a;
  border-radius: 8px;
}

.modal-content {
  border-color: none !important;
  background-color: #202020 !important;

  border-radius: 8px;
}

.btn-secondary {
  border: 0;
  background: rgba(255, 255, 255, 0.1) !important;
  color: white !important;
  border-radius: 8px !important;
}

.img-size-for-menu-item {
  width: 24px;
  height: 16px;
  margin-right: 8px;
}
.img-size-for-menu-item-Academy {
  width: 16px;
  height: 16px;
  margin-right: 8px !important;
  margin-left: 4px;
}

.img-size-for-menu-item-svg {
  width: 24px !important;
  height: 24px !important;
  margin-right: 8px !important;
}

.opacity-04 {
  opacity: 0.4;
}

.mat-progress-bar {
  background-color: #1f1f1f !important;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.mat-progress-bar .mat-progress-bar-fill::after {
  background-color: #ff9501;
}

.mat-progress-bar-background {
  fill: #1f1f1f !important;
}

.mat-progress-bar-buffer {
  background-color: #1f1f1f;
}

.tier-level-test {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 16px;
  letter-spacing: -0.004em;
  color: #ffffff !important;
}

.btn-orange {
  padding: 8px 16px;
  border-radius: 8px;
  gap: 8px;
  border: 0;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.004em;
  text-align: center;
  color: #ffffff;
  background: #ed8a00;

  &:hover {
    background: #d86800;
  }
  &:disabled {
    background: #ed8a00 !important;
    opacity: 0.4000000059604645;
  }
  .icon-btn-orange {
    width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}
.button-black {
  width: 24px !important;
  height: 24px !important;
  background: none !important;
  opacity: 0.4;

  &:hover {
    color: #ffffff !important;
    opacity: 1 !important;
  }
}

.button-grey {
  border: 0;
  background: #ffffff0d;
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 16px;
  gap: 8px;

  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: -0.004em;
  text-align: center;
  color: #ffffff66;
  &.-br8 {
    border-radius: 8px;
  }
  img {
    opacity: 0.4;
  }
  &:hover {
    color: #ffffff !important;

    background: #ffffff1a !important;
    span {
      color: #ffffff !important;
    }
    img {
      opacity: 1 !important;
    }
  }
  &:disabled {
    background: #ffffff0d;
    color: #ffffff66;

    opacity: 0.4000000059604645;
  }
}

@keyframes taadaa {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.conteiner-coning-soon {
  position: relative;
  .coning-soon {
    position: absolute;
    display: none;
    background: #000000;
    padding: 8px 12px;
    text-wrap: nowrap;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.004em;
    text-align: left;
    color: #ffffff !important;
    border-radius: 8px;
  }
  .coning-soon-top {
    top: -40px;
    left: calc(50% - 50px);
  }
  .coning-soon-right {
    top: calc(50% - 16px);
    right: -108px;
  }
  .coning-soon-bottom {
    bottom: -40px;
    left: calc(50% - 50px);
  }
  &:hover {
    .coning-soon {
      display: block;

      animation: taadaa 0.4s;
    }
  }
}

@media (max-width: 600px) {
  .btn-orange {
    font-size: 13px;

    .icon-btn-orange {
      width: 16px;
      height: 16px;
      margin-right: 4px;
    }
  }
}

.modal-dialog.dialog-wide {
  max-width: 640px !important;
  @media (max-width: 576px) {
    margin: 16px 0 0 !important;
    align-items: flex-start !important;
  }
}

.modal-dialog.dialog-ultra-wide {
  max-width: 880px !important;
  @media (max-width: 576px) {
    margin: 0 !important;
    align-items: flex-start !important;
  }
}

.cdk-overlay-container {
  z-index: 1100 !important;
}