// accordion

$accordionBorderColor: rgba(0, 0, 0, 0.1);
$accordionMarginColor: rgba(0, 0, 0, 0.05);

.accordion {
  // accordion card
  .card {
    margin-bottom: 0;

    .card-header {
      padding: 0;

      button {
        padding: 1rem;
        width: 100%;

        span {
          float: left;
          font-size: 1.1rem;
          text-align: start;
        }
      }
    }

    .card-body {
      padding: 1rem;
    }
  }

  // accordion with Border
  .collapse-border-item {
    &.card {
      border-bottom: 0;

      .card-header {
        padding: 0;
      }

      &:last-child {
        border-bottom: 1px solid $accordionBorderColor;
      }
    }
  }

  // accordion with Margin
  .collapse-margin {
    box-shadow: 0 2px 15px 0 $accordionMarginColor !important;
  }
  .btn-link {
    color: inherit;
  }
}

// accordion icon animation
.collapse-icon {
  [aria-expanded='true'],
  [aria-expanded='false'] {
    &:after {
      content: '';
      position: absolute;
      background-image: url(str-replace(
        str-replace($chevron-up, 'currentColor', $body-color),
        '#',
        '%23'
      ));
      background-size: 14px;
      top: 58%;
      right: 1rem;
      width: 14px;
      height: 11px;
      margin-top: -8px;
      background-repeat: no-repeat;
      background-position: center;
      transition: all 300ms linear 0s;
      color: #6e6b7b;
    }
  }
}
